<template>
	<div class="Lists w">
		<div class="nav bgfff br4 mt24 plr32 ptb24">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>政策列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<!-- 搜索框 start -->
			<div class="search flex align-center mt24">
				<div class="input-wrap pr00 flex align-center">
					<!-- <div class="prefix pl32">
						全部分类<i class="el-icon-caret-bottom mlr16"></i>
						<span class="line">|</span>
					</div> -->
					<el-cascader
						v-if="level > 0"
						v-model="curTypeArrs"
						:options="typeArrs"
						@change="cascaderChange"
						:props="{
							value: 'id',
							label: 'name',
							checkStrictly: true,
						}"
						placeholder="请选择"
						:show-all-levels="false"
						clearable
						filterable
					></el-cascader>
					<span
						v-if="level > 0"
						class="line"
						style="color: #dddddd; margin-top: -2px"
						>|</span
					>

					<el-input
						class="input"
						v-model="keyword"
						placeholder="搜索你想要的内容"
						@change="searchFun"
					>
					</el-input>
					<el-button @click="searchFun" class="button"
						><i class="el-icon-search"></i
					></el-button>
				</div>
			</div>
			<!-- 搜索框 end -->

			<!-- 地址选择 start -->
			<div v-if="is_area == 1" class="address-wrap">
				<div class="title flex ptb24">
					<div v-for="item in ['province', 'city', 'district']" :key="item">
						<div
							v-if="addressObj[item + 'Arr'].length > 0"
							@click="titleClick(item)"
							:class="{ active: addressSign == item }"
						>
							{{
								item == "province"
									? addressObj[item] || "全国"
									: addressObj[item]
							}}<i
								class="ml8"
								:class="{
									'el-icon-caret-top': addressSign == item,
									'el-icon-caret-bottom': addressSign != item,
								}"
							></i>
						</div>
					</div>
				</div>
				<el-collapse v-model="addressSign" accordion>
					<el-collapse-item
						v-for="item in ['province', 'city', 'district']"
						:key="item"
						:name="item"
					>
						<div class="list flex flex-wrap">
							<div
								@click="itemClick(item)"
								v-for="(item, index) in addressObj[item + 'Arr']"
								:key="index"
								class="item"
							>
								{{ item.n }}
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
			<!-- 地址选择 end -->
		</div>

		<div class="container">
			<div style="min-height: 600px" v-loading="listLoading">
				<div v-if="arrs.length > 0 || listLoading" class="flex flex-wrap">
					<div
						class="item-div"
						v-for="(item, index) in arrs"
						:key="index"
						@click="jumpDetail(item)"
					>
						<ItemCon
							class="bgfff br4 plr24 pb24 global-hover"
							:isTit="false"
							:item="item"
							:style="{ width: '100%' }"
						></ItemCon>
					</div>
				</div>
				<div
					v-else
					class="mt24 bgfff br4"
					style="overflow: hidden; padding-bottom: 60px"
				>
					<Empty></Empty>
				</div>
			</div>
		</div>

		<!-- 分页 start -->
		<div class="page-wrap flex justify-end mt32">
			<el-pagination
				:background="true"
				layout="prev, pager, next"
				:total="total"
				:current-page="page"
				:page-size="pageSize"
				@current-change="change"
				hide-on-single-page
			>
			</el-pagination>
		</div>
		<!-- 分页 end -->

		<!-- 是否可以查看弹窗 -->
		<AlonePermission
			ref="myAlonePermission"
			:detail="curDetail"
			:detailInfo="curDetailInfo"
			@confirm="jumpConfirm"
			@cancel="jumpCancel"
		></AlonePermission>
	</div>
</template>
<script>
import ItemCon from "@/components/ItemCon.vue";
import AlonePermission from "@/components/AlonePermission.vue";
export default {
	name: "Lists",
	components: {
		ItemCon,
		AlonePermission,
	},
	data() {
		return {
			toName: "", // 去哪里了  除了 detail  其他的全部清楚默认数据
			id: 0,
			keyword: "", // 搜索关键字
			is_area: 0, //   是否存在地区  0否 1是
			level: 0, //  分类层级  默认1

			addressSign: "", // 地址的标记  当前是哪个类型
			// 地址数据的存储
			addressObj: {
				provinceArr: [],
				province: "", // 当前的省
				cityArr: [],
				city: "", // 当前的城市
				districtArr: [],
				district: "", // 当前的区
			},
			typeArrs: [], // 分类
			curTypeArrs: [], // 当前的分类

			arrs: [],
			total: 0, // 总条数
			pageSize: 16, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,

			curDetail: {}, // 当前要进入的详情   与 alone-permission 组件配合使用
			curDetailInfo: {}, // 当前要进入的详情 的信息
			jumpLoading: false, // 是否在请求 跳转详情的接口

			isCurrentView: false, // 当前是否在当前页面
			scorllTop: 0, // 距离顶部得距离
		};
	},
	created() {
		let arr = this.$localGetItem("threeCity") || [];
		arr.unshift({
			n: "全国",
			c: "",
			d: [],
		});

		this.addressObj.provinceArr = arr;
	},
	beforeRouteEnter(to, from, next) {
		// 在渲染该组件的对应路由被验证前调用
		// 不能获取组件实例 `this` ！
		// 因为当守卫执行时，组件实例还没被创建！
		console.log("beforeRouteEnter");
		next();
	},
	beforeRouteLeave(to, from, next) {
		// 在导航离开渲染该组件的对应路由时调用
		// 与 `beforeRouteUpdate` 一样，它可以访问组件实例 `this`
		this.toName = to.name;
		next();
	},
	mounted() {
		window.addEventListener("scroll", this.listsScroll);
	},
	// keep-alive 活跃的
	activated() {
		this.isCurrentView = true;

		if (this.scorllTop > 0) {
			document.documentElement.scrollTop = this.scorllTop;
			document.body.scrollTop = this.scorllTop;
		}

		if (this.toName != "Detail") {
			this.getparams();
			this.getList();
		} else {
			this.toName = "";
		}
	},
	// keep-alive 失去活跃
	deactivated() {
		this.isCurrentView = false;
		if (this.toName != "Detail") {
			this.toName = "";
			this.id = 0;
			this.keyword = ""; // 搜索关键字
			this.is_area = 0; //   是否存在地区  0否 1是
			this.level = 0; //  分类层级  默认1
			this.addressSign = "";
			this.addressObj = {
				provinceArr: this.addressObj.provinceArr,
				province: "", // 当前的省
				cityArr: [],
				city: "", // 当前的城市
				districtArr: [],
				district: "", // 当前的区
			};
			this.typeArrs = []; // 分类
			this.curTypeArrs = []; // 当前的分类
			this.arrs = [];
			this.total = 0; // 总条数
			this.page = 1; // 当前的页码
			this.listLoading = false;
			this.curDetail = {};
			this.curDetailInfo = {};
			this.jumpLoading = false;
		}
	},
	destroyed() {
		window.removeEventListener("scroll", this.listsScroll);
	},
	methods: {
		listsScroll() {
			if (this.isCurrentView) {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
		// 支付取消
		jumpCancel() {
			// this.curDetail = {};
			// this.curDetailInfo = {};
		},
		// 支付成功 进入详情
		jumpConfirm() {
			this.$router.push({
				name: "Detail",
				query: {
					id: this.curDetail.id,
				},
			});
		},
		// 跳转详情页
		async jumpDetail(item) {
			// 调用接口 判断是否可以直接进入
			//  不行    弹窗 去支付
			//  可以    就直接进入

			if (this.jumpLoading) {
				this.$message.info("您点击的太快了");
				return false;
			}

			this.jumpLoading = true;
			this.curDetail = { ...item };
			let res = await this.$isEnterDetailFun(
				{
					id: item.id,
				},
				true
			);

			this.jumpLoading = false;
			if (res == "catch") {
				return false;
			}

			this.curDetailInfo = res;

			if (res.is_pay == 1) {
				this.popupFun(true, "myAlonePermission");
				return false;
			}

			this.jumpConfirm();
		},

		// 分类切换
		cascaderChange(e) {
			this.curTypeArrs = e;
			this.searchFun();
		},
		// 地址元素点击
		itemClick(item) {
			switch (this.addressSign) {
				case "province":
					this.addressObj.province = item.n;
					this.addressObj.cityArr = [];
					this.addressObj.city = "";
					this.addressObj.districtArr = [];
					this.addressObj.district = "";

					if (item.d.length > 0) {
						if (item.d[0].n != item.n) {
							item.d.unshift({
								...item,
								d: [],
							});
						}

						this.addressObj.cityArr = item.d;
						this.addressObj.city = item.n;
						this.addressSign = "city";
						this.searchFun();
					} else {
						this.addressSign = "";
						this.searchFun();
					}
					break;

				case "city":
					this.addressObj.city = item.n;
					this.addressObj.districtArr = [];
					this.addressObj.district = "";

					if (item.d.length > 0) {
						if (item.d[0].n != item.n) {
							item.d.unshift({
								...item,
								d: [],
							});
						}

						this.addressObj.districtArr = item.d;
						this.addressObj.district = item.n;
						this.addressSign = "district";
						this.searchFun();
					} else {
						this.addressSign = "";
						this.searchFun();
					}
					break;

				case "district":
					this.addressObj.district = item.n;
					this.addressSign = "";
					this.searchFun();
					break;
			}
		},
		// 顶部地址点击切换
		titleClick(str) {
			this.addressSign = str == this.addressSign ? "" : str;
		},

		// 获取分类
		getTypeFun(category_id) {
			this.$API
				.typeAjax({
					category_id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.typeArrs = [...res.data];
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		// 搜索列表
		searchFun() {
			this.page = 1;
			this.getList();
		},

		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			this.addressSign = "";

			let { page, pageSize, keyword, addressObj, id, curTypeArrs } = this;

			let { province, city, district: area } = addressObj;

			province = province == "全国" ? "" : province;
			city = city == province ? "" : city;
			area = area == city ? "" : area;

			this.listLoading = true;
			this.$pageScrollTop();

			let url = "kingSearchAjax";

			if (this.id == 0) {
				url = "searchAjax";
			}

			this.$API[url]({
				page,
				pagesize: pageSize,
				list_rows: pageSize,
				pageSize,
				province,
				city,
				area,
				keywords: keyword,

				nav_id: id,
				one_id: curTypeArrs[0] || "",
				two_id: curTypeArrs[1] || "",
				category_id: curTypeArrs[2] || "",
			})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		// 获取参数
		getparams() {
			let { id = 0, is_area = 0, level = 0 } = this.$route.params;
			let { keyword = "" } = this.$route.query;
			this.id = id;
			this.is_area = is_area;
			this.level = level;
			this.keyword = keyword;

			if (level > 0) this.getTypeFun(id);
		},

		/**
		 * ! 是否显示弹窗
		 * @param {sign} sign 显示隐藏 true 显示 false 隐藏
		 * @param {ref} ref 那个类型
		 */
		popupFun(sign, ref) {
			if (sign) {
				try {
					this.$refs[ref].open();
				} catch (error) {
					this.$refs[ref].show();
				}
			} else {
				try {
					this.$refs[ref].close();
				} catch (error) {
					this.$refs[ref].hide();
				}
			}
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.search {
		input {
			height: 44px;
			border: none;
		}
	}

	.address-wrap {
		.el-collapse-item__header {
			border: none;
			display: none;
		}

		.el-collapse-item__wrap {
			border: none;
		}

		.el-collapse-item__arrow {
			display: none;
		}
	}
}

.Lists {
	@extend .h100;

	.nav {
		.search {
			.input-wrap {
				flex: 1;
				overflow: hidden;
				border-radius: 100px;
				border: 2px solid #ff8300;

				.prefix {
					display: flex;
					align-items: center;
					color: #333333;
					cursor: pointer;

					.line {
						color: #dddddd;
					}
				}

				.input {
					flex: 1;
				}

				.button {
					padding: 0;
					margin: 0;
					border: none;
					border-radius: 100px;
					position: absolute;
					right: 2px;
					top: 50%;
					transform: translateY(-50%);
					width: 88px;
					height: 40px;
					background: #ff8300;
					font-size: 16px;
					font-weight: 400;
					color: #ffffff;
				}
			}

			.sign {
				width: 36px;
				height: 38px;
			}
		}

		.address-wrap {
			.title {
				border-bottom: 1px dashed #dddddd;

				> div {
					margin-right: 96px;
					cursor: pointer;
				}
			}

			.el-collapse {
				display: flex;
				border: none;
				color: #333333;
			}

			.active {
				color: #ff8300;
			}

			.list {
				.item {
					margin-top: 24px;
					margin-right: 40px;
					cursor: pointer;
				}
			}
		}
	}

	.container {
		.item-div {
			width: 50%;
			margin-top: 16px;
			overflow: hidden;

			&:nth-child(odd) {
				padding-right: 4px;
			}

			&:nth-child(even) {
				padding-left: 4px;
			}
		}
	}

	.page-wrap {
		padding: 54px 24px 40px 24px;
	}
}
</style>
